import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../core/services/user.service';
import { getCookie, setCookie } from '../common/util';
import { DialogParam, GeneralDialogService } from '../common/service/general-dialog.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})

export class ShellComponent implements OnInit {
  sideMenuList = [
    {
      class: "default-menu-section",
      button: [
        {
          type: 'button',
          label: "SHELL.DASHBOARD",
          ngRouterLink: "/admin/dashboard",
          icon: "dashboard",
          iconTheme: "outlined",
        },
      ],
    },
    {
      class: "default-menu-section",
      operationCodes: ['FWA_OP:rSystemMsg'],
      button: [
        {
          type: 'button',
          label: "SHELL.SYSTEM_MESSAGE",
          ngRouterLink: "/admin/systemMessage",
          iconUrl: "../assets/icon/common/message.svg",
          iconTheme: "outlined",
        },
      ],
    },
    {
      class: "communication-menu-section",
      title: "SHELL.COMMUNICATION",
      operationCodes: ['FWA_OP:rMemoAdmin', 'FWA_OP:rInstructionAdmin'],
      button: [
        {
          type: 'button',
          operationCodes: "FWA_OP:rMemoAdmin",
          label: "CIRCULAR_MEMO.CIRCULAR_MEMO",
          ngRouterLink: "/admin/circularMemo",
          icon: "sticky_note_2",
          iconTheme: "outlined"
        }, {
          type: 'button',
          operationCodes: "FWA_OP:rInstructionAdmin",
          label: "INSTRUCTION.INSTRUCTION",
          ngRouterLink: "/admin/instruction",
          icon: "assignment",
          iconTheme: "outlined"
        }, {
          type: 'button',
          operationCodes: "FWA_OP:rGroups",
          label: "ALERT_GROUPS.ALERT_GROUPS",
          ngRouterLink: "/admin/alertGroups",
          icon: "notifications_none",
          iconTheme: "outlined"
        },
      ],
    },
    {
      class: "inspection-menu-section",
      title: "SHELL.INSPECTION",
      operationCodes: ['FWA_OP:rInspection', 'FWA_OP:rInspectionRecordWeb', 'FWA_OP:rPoint', 'FWA_OP:rInspectionChecklist'],
      button: [
        {
          type: 'collapsible',
          label: 'SHELL.INSPECTION',
          icon: "offline_pin",
          iconTheme: "outlined",
          operationCodes: ['FWA_OP:rInspection', 'FWA_OP:rInspectionRecordWeb'],
          button: [
            {
              operationCodes: "FWA_OP:rInspection",
              label: "INSPECTION.SETTING",
              ngRouterLink: "/admin/inspectionSetting"
            },
            {
              operationCodes: "FWA_OP:rInspectionRecordWeb",
              label: "INSPECTION.INSPECTION_RECORDS",
              ngRouterLink: "/admin/inspectionRecord"
            }
          ]
        },
        {
          type: 'button',
          operationCodes: "FWA_OP:rPoint",
          label: "INSPECTION.POINT_MANAGEMENT",
          ngRouterLink: "/admin/point",
          icon: "category",
          iconTheme: "outlined"
        },
        {
          type: 'button',
          operationCodes: "FWA_OP:rInspectionChecklist",
          label: "INSPECTION.INSPECTION_CHECKLIST",
          ngRouterLink: "/admin/inspectionChecklist",
          icon: "format_list_numbered",
          iconTheme: "outlined"
        },
      ],
    },
    {
      class: "defect-menu-section",
      title: "SHELL.PORTFOLIO_MANAGEMENT",
      operationCodes: ['FWA_OP:rDefectRptApp', 'FWA_OP:rPatrol', 'FWA_OP:rPatrolLoc', 'FWA_OP:rRoute', 'FWA_OP:rKioskPas', 'FWA_OP:rBackupSna', 'FWA_OP:rReportSch', 'FWA_OP:rReuseNFC', 'FWA_OP:rReport', 'FWA_OP:rErrorReason'],
      button: [
        {
          type: 'button',
          operationCodes: "FWA_OP:rDefectRptApp",
          label: "DEFECT_REPORT.DEFECT_REPORTING",
          ngRouterLink: "/admin/defectReport",
          icon: "report_gmailerrorred",
          iconTheme: "outlined"
        },
        {
          type: 'collapsible',
          label: 'EPATROL.EPATROL',
          icon: "admin_panel_settings",
          iconTheme: "outlined",
          operationCodes: ['FWA_OP:rPatrol', 'FWA_OP:rPatrolLoc', 'FWA_OP:rRoute', 'FWA_OP:rKioskPas', 'FWA_OP:rBackupSna', 'FWA_OP:rReportSch', 'FWA_OP:rReuseNFC', 'FWA_OP:rReport', 'FWA_OP:rErrorReason'],
          button: [
            {
              operationCodes: "FWA_OP:rPatrol",
              label: "EPATROL.PATROL_RECORDS",
              ngRouterLink: "/admin/patrolRecords"
            },
            {
              operationCodes: ['FWA_OP:rPatrolLoc', 'FWA_OP:rRoute', 'FWA_OP:rKioskPas', 'FWA_OP:rBackupSna', 'FWA_OP:rReportSch', 'FWA_OP:rReuseNFC', 'FWA_OP:rErrorReason'],
              label: "EPATROL.SETTINGS",
              ngRouterLink: "/admin/ePatrolSettings"
            },
            {
              operationCodes: 'FWA_OP:rReport',
              label: "EPATROL.DOWNLOAD_REPORT",
              ngRouterLink: "/admin/downloadReport"
            }
          ]
        }
      ],
    },
    {
      class: "administration-menu-section",
      title: "SHELL.ADMINISTRATION",
      operationCodes: ['FWA_OP:rUserMgt', 'FWA_OP:rRoleMgt', "FWA_OP:rMO", 'FWA_OP:rDeviceMgt', "FWA_OP:rCommonDevice", "FWA_OP:rPublicHol"],
      button: [
        {
          type: 'button',
          operationCodes: "FWA_OP:rUserMgt",
          label: "USER_MANAGEMENT.USER_MANAGEMENT",
          ngRouterLink: "/admin/userManagement",
          icon: "people",
          iconTheme: "outlined"
        },
        {
          type: 'button',
          operationCodes: "FWA_OP:rRoleMgt",
          label: "ROLE_MANAGEMENT.ROLE_MANAGEMENT",
          ngRouterLink: "/admin/roleManagement",
          icon: "person_pin",
          iconTheme: "outlined"
        },
        {
          type: 'button',
          operationCodes: "FWA_OP:rMO",
          label: "MO_BUILDINGS.MO_BUILDINGS",
          ngRouterLink: "/admin/moBuildings",
          icon: "apartment",
          iconTheme: "outlined"
        },
        {
          type: 'button',
          operationCodes: "FWA_OP:rDeviceMgt",
          label: "DEVICE_MANAGEMENT.DEVICE_MANAGEMENT",
          ngRouterLink: "/admin/deviceManagement",
          icon: "app_settings_alt",
          iconTheme: "outlined"
        },
        {
          type: 'button',
          operationCodes: "FWA_OP:rCommonDevice",
          label: "COMMON_DEVICE.COMMON_DEVICE_MANAGEMENT_MENU",
          ngRouterLink: "/admin/commonDeviceManagement",
          icon: "mobile_friendly",
          iconTheme: "outlined"
        },
        {
          type: 'collapsible',
          label: 'SYSTEM_PARAMETERS.MASTER_DATA',
          icon: "chrome_reader_mode",
          iconTheme: "outlined",
          operationCodes: ['FWA_OP:rCodeDescMgt', 'FWA_OP:rSysParamMgt'],
          button: [
            {
              operationCodes: "FWA_OP:rCodeDescMgt",
              label: "CODE_DESCRIPTIONS.CODE_DESCRIPTIONS",
              ngRouterLink: "/admin/CodeDescriptions",
            },
            {
              operationCodes: "FWA_OP:rSysParamMgt",
              label: "SYSTEM_PARAMETERS.SYSTEM_PARAMETERS",
              ngRouterLink: "/admin/systemParameters",
            }
          ]
        },
        {
          type: 'button',
          operationCodes: "FWA_OP:rPublicHol",
          label: "PUBLIC_HOLIDAY.PUBLIC_HOLIDAY_SETTING",
          ngRouterLink: "/admin/publicHoliday",
          icon: "event",
          iconTheme: "outlined"
        },
      ],
    },
  ];

  userInfo: any = {
    displayName: '',
    title: '',
    department: '',
    email: '',
  };
  title = 'frontline-workplace-frontend-admin-portal';

  languageOptions: Array<any> = [
    {
      key: 'en',
      label: 'English',
      selectedDisplay: 'EN'
    },
    {
      key: 'tc',
      label: '繁體中文',
      selectedDisplay: '繁'
    },
    {
      key: 'sc',
      label: '简体中文',
      selectedDisplay: '简'
    }
  ];
  languageCurId?: string = 'en';
  selectedLan = null;
  dislogParam: DialogParam;

  logoUrl: string = '';

  constructor(
    private userService: UserService,
    private translate: TranslateService,
    private generalDialogService: GeneralDialogService,
  ) {
    this.generalDialogService.dialogParamSubject.subscribe(dislogParam => {
      this.dislogParam = dislogParam;
    });
  }

  ngOnInit(): void {
    this.userInfo = this.userService.currentUserInfo;
    const cookieLan = getCookie('cLan');
    const initLanAfterLogin = this.userInfo?.langPreference ? this.userInfo.langPreference : cookieLan;
    this.onLanSelectionChange(initLanAfterLogin);
    this.sideMenuList = this.checkOperationCode(this.sideMenuList);
  }

  checkOperationCode(menus: any[]) {
    const result = [];
    for (const menu of menus) {
      if (menu['operationCodes']) {
        if (this.hasOperationCode(menu['operationCodes'])) {
          if (menu['button']) {
            menu['button'] = this.checkOperationCode(menu['button']);
          }
          result.push(menu);
        }
      } else {
        result.push(menu);
      }
    }
    return result;
  }

  hasOperationCode(curCode: [] | string) {
    let PERMISSIONS = this.userService.permissions;
    let _temp = PERMISSIONS ? PERMISSIONS : [];

    if (curCode instanceof Array) {
      let isInclude = false;
      for (const c of curCode) {
        if (c && _temp.includes(c)) {
          isInclude = true;
          break;
        }
      }
      return isInclude;
    } else {
      // string
      if (curCode) {
        return _temp.indexOf(curCode) > -1;
      }
    }
  }

  onLanSelectionChange(e): void {
    const curLan = e;
    this.languageCurId = curLan;
    this.selectedLan = this.languageOptions.filter((item) => { return item.key == curLan })[0];
    this.translate.use(curLan);
    setCookie('cLan', curLan);
    this.userService.updateUserLangPreference({ "langPreference": curLan });

    if (this.languageCurId == 'en') {
      this.logoUrl = '../assets/icon/logo/en-logo.svg';
    } else if (this.languageCurId == 'tc') {
      this.logoUrl = '../assets/icon/logo/tc-logo.png';
    } else {
      this.logoUrl = '../assets/icon/logo/sc-logo.png';
    }
  }

  showDialog(): void {
    this.generalDialogService.show({
      header: {
        text: 'SHELL.LOG_OUT',
      },
      content: {
        text: 'SHELL.ARE_YOU_SURE_YOU_WANT_TO_LOGOUT',
      },
      primaryBtn: {
        first: true,
        text: 'COMMON.YES',
        onClick: () => {
          this.generalDialogService.close();
          this.userService.logout();
        }
      },
      defaultBtn: {
        text: 'COMMON.CANCEL',
        onClick: () => {
          this.generalDialogService.close();
        }
      }
    });
  }

}
